import React, { useEffect, useState } from "react";
import IntroMinor from "./IntroMinor";
import { PlayIcon, XMarkIcon } from "@heroicons/react/24/outline";

const Intro = () => {
  const [video, setVideo] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setVideo(false);
      }
    });
  }, []);
  return (
    <div className="intro" id="intro">
      <div className="container">
        <div className="intro-inner">
          <IntroMinor
            image={
              "/images/swift7.jpeg"
            }
            title="STRONG SECURITY"
            text={"Protection against DDoS attacks full data encryption"}
          />
          <IntroMinor
            image={
              "/images/swift8.jpeg"
            }
            title="WORLD COVERAGE"
            text="Providing services in 99% countries
            around all the globe"
          />
          <IntroMinor
            image={
              "/images/swift9.jpeg"
            }
            title="PAYMENT OPTIONS"
            text="Popular methods: Visa, MasterCard,
            bank transfer, cryptocurrency"
          />
          <IntroMinor
            image={"/images/swift1.jpeg"}
            title="MOBILE APP"
            text="Trading via our Mobile App, Available
            in Play Store & App Store"
          />
          <IntroMinor
            image={
              "/images/swift2.jpeg"
            }
            title="COST EFFICIENCY"
            text="Reasonable trading fees for takers
            and all market makers"
          />
          <IntroMinor
            image={
              "/images/swift3.jpeg"
            }
            title="HIGH LIQUIDITY"
            text="Fast access to high liquidity orderbook
            for top currency pairs"
          />
        </div>
      </div>
      <div className="vid">
        <PlayIcon
          onClick={() => {
            setVideo(true);
          }}
        />
      </div>
      {video ? (
        <div className="overlay">
          <div className="overlay-close">
            <XMarkIcon
              onClick={() => {
                setVideo(false);
              }}
            />
          </div>
          <iframe
            src="https://www.youtube.com/embed/41JCpzvnn_0"
            controls
            autoPlay
            title="vid"
          />
        </div>
      ) : null}
    </div>
  );
};

export default Intro;
