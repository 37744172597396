import React from "react";
import ExpertCard from "./ExpertCard";

const Experts = () => {
  return (
    <div className="experts" id="experts">
      <div className="container">
        <h1 className="about-title">
          our <span>experts</span>
        </h1>
        <div className="about-subtext">
          <p>A TALENTED TEAM OF CRYPTOCURRENCY EXPERTS BASED IN LONDON</p>
        </div>
        <div className="expert-inner">
          <ExpertCard
            image={
              "https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDV8fHBlb3BsZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60"
            }
            name="mark williams"
            position="ceo founder"
            facebook="#"
            twitter="#"
            google="#"
          />
          <ExpertCard
            image={
              "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fHBlb3BsZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60"
            }
            name="mario verrati"
            position="director"
            facebook="#"
            twitter="#"
            google="#"
          />
          <ExpertCard
            image={
              'https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjZ8fHBlb3BsZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60'
            }
            name="bella rose"
            position="bitcoin consultant"
            facebook="#"
            twitter="#"
            google="#"
          />
        </div>
      </div>
    </div>
  );
};

export default Experts;
