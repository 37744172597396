import React from "react";
import Minor from "./Minor";

const Steps = () => {
  return (
    <div>
      <div className="container">
        <div className="steps-inner">
          <Minor
            image={
              "/images/swift4.jpeg"
            }
            title="download our wallet"
            subtext={"Get it on PC or Mobile to create, send and receive funds"}
          />
          <Minor
            image={
              "/images/swift5.jpeg"
            }
            title="Add coins to your Wallet"
            subtext={
              "Add bitcoins you’ve created or exchanged via credit card."
            }
          />
          <Minor
            image={
              "/images/swift6.jpeg"
            }
            title="Buy/Sell with Wallet"
            subtext={"Enter receivers address, specify the amount and send"}
          />
        </div>
      </div>
    </div>
  );
};

export default Steps;
