import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";

const Showcase = () => {
  // const PrevBtn = (props) => {
  //   const { className, onClick } = props;
  //   return (
  //     <>
  //       <div className={className} onClick={onClick}>
  //         <ChevronLeftIcon className="arrow" />
  //       </div>
  //     </>
  //   );
  // };
  // const NextBtn = (props) => {
  //   const { className, onClick } = props;
  //   return (
  //     <>
  //       <div className={className} onClick={onClick}>
  //         <ChevronRightIcon className="arrow" />
  //       </div>
  //     </>
  //   );
  // };
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    // prevArrow: <PrevBtn />,
    // nextArrow: <NextBtn />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slide-contain">
      <Slider {...settings}>
        <div className="slide-1">
          <div>
            <h1>
              <span>stocks</span>, commodity <span>cfds & crypto</span> investment
            </h1>
            <button>invest now</button>
          </div>
        </div>
        <div className="slide-2">
          <div>
            <h1>
              <span>secured investment</span> broker you can <span>trust</span>
              
            </h1>
            <button>our markets</button>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Showcase;
