import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  UserIcon,
  UserPlusIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
const Navbar = () => {
  const [nav, setNav] = useState(false);
  const { currentUser } = useSelector((state) => state.user);
  return (
    <div className="main-nav">
      <div className="container">
        <div className="nav-inner">
          <a href={"/"} className="logo">
            <img src="../images/logo.png" alt="#" />
            <span>swift gainz</span>
          </a>
          <div className="bar">
            <Bars3Icon onClick={() => setNav(!nav)} />
          </div>
          <div className={`forlinks ${nav ? "active" : ""}`}>
            {nav ? (
              <div className="bar">
                <XMarkIcon onClick={() => setNav(!nav)} />
              </div>
            ) : null}
            <div className="nav-links">
              <a href="#about" onClick={() => setNav(false)}>
                about
              </a>
              <a href="#intro" onClick={() => setNav(false)}>
                Intro
              </a>
              <a href="#packages" onClick={() => setNav(false)}>
                Packages
              </a>
              <a href="#calculator" onClick={() => setNav(false)}>
                Calculator
              </a>
              <a href="#experts" onClick={() => setNav(false)}>
                Experts
              </a>
              <a href="#ceo" onClick={() => setNav(false)}>
                CEO Speech
              </a>
            </div>
            {currentUser ? null : (
              <div className="login-sign">
                <Link to="/login" className="btn-login">
                  <UserIcon />
                  login
                </Link>
                <Link to="/signup" className="btn-signup">
                  <UserPlusIcon />
                  register
                </Link>
              </div>
            )}
            {currentUser && !currentUser?.isAdmin && (
              <>
                <Link
                  className="log-signup"
                  to={`/dashboard/${currentUser._id}`}
                >
                  dashboard
                </Link>
              </>
            )}
            {currentUser?.isAdmin && (
              <Link to={"/admin"} className="log-signup adm">
                admin
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
