import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  ArrowUturnLeftIcon,
  UserGroupIcon,
  ArrowUpTrayIcon,
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { logOut } from "../redux/userRedux";
import { publicRequest } from "../requestMethod";
import Moment from "react-moment";
import { toast } from "react-toastify";
const Admin = () => {
  const [creditPop, setCreditPop] = useState(false);
  const [creditValue, setCreditValue] = useState(0);
  const [profitPop, setProfitPop] = useState(false);
  const [profitValue, setProfitValue] = useState(0);
  const [debitPop, setDebitPop] = useState(false);
  const [debitValue, setDebitValue] = useState(0);
  const [bonusPop, setBonusPop] = useState(false);
  const [bonusValue, setBonusValue] = useState(0);
  const [credit, setCredit] = useState(0);
  const [debit, setDebit] = useState(0);
  const [openExtra, setOpenExtra] = useState(null);
  const [orders, setOrders] = useState([]);
  const [removeProfit, setRemoveProfit] = useState(false);
  const [rpvalue, setRpvalue] = useState("");
  const [rbvalue, setRbvalue] = useState("");
  const [removeBonus, setRemoveBonus] = useState(false);
  const dispatch = useDispatch();
  const toggle = (i) => {
    if (openExtra === i) {
      return setOpenExtra(null);
    }
    setOpenExtra(i);
  };
  const [userData, setUserData] = useState([]);
  const getUsers = async () => {
    try {
      const data = await publicRequest.get("/user");
      setUserData(data.data.users);
    } catch (error) {
      console.log(error);
    }
  };
  const getOrders = async () => {
    try {
      const data = await publicRequest.get("/order");
      setOrders(data.data.allOrders);
    } catch (error) {
      console.log(error);
    }
  };
  const getCstats = async () => {
    try {
      const data = await publicRequest.get("/cash");
      setCredit(data?.data?.allCash[0].deposit);
      setDebit(data?.data?.allCash[0].widthdraw);
    } catch (error) {
      console.log(error);
    }
  };

  const updateOrder = async (id) => {
    try {
      // eslint-disable-next-line
      const data = await publicRequest.put("/order/" + id, {
        status: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateUserDeposit = async (id, exist) => {
    try {
      const userDepo = await publicRequest.put(`/user/${id}`, {
        deposit: exist + Number(creditValue),
      });
      // eslint-disable-next-line
      const cashDepo = await publicRequest.put(
        "/cash/640b6b612a3fc76e083e956f",
        {
          deposit: credit + Number(creditValue),
          widthdraw: debit,
        }
      );
      toast.info(userDepo.data.message);
      setCreditPop(false);
    } catch (error) {
      console.log(error);
    }
  };
  const updateDebit = async (id, exist) => {
    try {
      const userDepo = await publicRequest.put(`/user/${id}`, {
        widthdrawals: exist + Number(debitValue),
      });
      // eslint-disable-next-line
      const cashDepo = await publicRequest.put(
        "/cash/640b6b612a3fc76e083e956f",
        {
          deposit: credit,
          widthdraw: debit + Number(debitValue),
        }
      );
      toast.info(userDepo.data.message);
      setDebitPop(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
    getCstats();
    // eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    getOrders();
    // eslint-disable-next-line
  }, [orders, userData]);

  const userBonusUpdate = async (id, exist) => {
    try {
      const data = await publicRequest.put(`/user/${id}`, {
        bonus: exist + Number(bonusValue),
      });
      toast.info(data.data.message);
      setBonusPop(false);
    } catch (error) {
      console.log(error);
    }
  };
  const userProfitUpdate = async (id, exist) => {
    try {
      const data = await publicRequest.put(`/user/${id}`, {
        profit: exist + Number(profitValue),
      });
      toast.info(data.data.message);
      setProfitPop(false);
    } catch (error) {
      console.log(error);
    }
  };

  const Deduct = async (id, exist, topic) => {
    try {
      if (topic === "bonus") {
        const data = await publicRequest.put(`/user/${id}`, {
          bonus: exist - Number(rbvalue),
        });
        if (data) {
          toast.info("Bonus Deducted Successfully");
          setRemoveBonus(false);
        }
      } else {
        const data = await publicRequest.put(`/user/${id}`, {
          profit: exist - Number(rpvalue),
        });
        if (data) {
          toast.info("Profit Deducted Successfully");
          setRemoveProfit(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteUser = async (id) => {
    try {
      const deletedUser = await publicRequest.delete(`/user/${id}`);

      toast.info(deletedUser.data.message);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div className="container">
        <div className="adt-tp">
          <div className="packbtn">
            <Link to={"/"} className="packing-top">
              <ArrowUturnLeftIcon />
            </Link>
            <Link
              to={"/"}
              className="pct-btn"
              onClick={() => {
                dispatch(logOut());
              }}
            >
              Log out
            </Link>
          </div>
        </div>
        <h1 className="admin-title">
          admin <span>dashboard</span>
        </h1>
        <div className="stats">
          <div className="dash-user-box">
            <div className="dash-user-text">
              <p>total deposit</p>
              <h3>$ {credit}</h3>
            </div>
            <div className="dash-icon">
              <ArrowDownTrayIcon />
            </div>
          </div>
          <div className="dash-user-box">
            <div className="dash-user-text">
              <p>total withdrawals</p>
              <h3>$ {debit}</h3>
            </div>
            <div className="dash-icon">
              <ArrowUpTrayIcon />
            </div>
          </div>
          <div className="dash-user-box">
            <div className="dash-user-text">
              <p>total users</p>
              <h3>{userData.length}</h3>
            </div>
            <div className="dash-icon">
              <UserGroupIcon />
            </div>
          </div>
        </div>
        <div className="admin-user-box">
          <div className="admin-user-head">
            <h4>all orders</h4>
            <div className="ad-search">
              <input type="text" placeholder="Search Order by Name" />
              <MagnifyingGlassIcon />
            </div>
          </div>
          <div className="table-container">
            <table className="transact-table admin">
              <thead>
                <tr>
                  <th>name</th>
                  <th>amount</th>
                  <th>status</th>
                  <th className="btn-header">action</th>
                  <th>transaction iD</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr key={order._id}>
                    <td>
                      <span>{order.name}</span>
                    </td>
                    <td>
                      <span>
                        {order.amount > 2 ? "$" : "BTC"} {order.amount}
                      </span>
                    </td>
                    <td>
                      <span
                        className={`table-complete ${
                          order.status ? "complete" : "pending"
                        }`}
                      >
                        {order.status ? "Completed" : "Pending"}
                      </span>
                    </td>
                    <td className="td-btn">
                      <button
                        className="btn-header-btn"
                        onClick={() => {
                          updateOrder(order._id);
                        }}
                      >
                        done
                      </button>
                    </td>
                    <td>
                      <span>{order._id}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="admin-user-box">
          <div className="admin-user-head">
            <h4>all users</h4>
            <div className="ad-search">
              <input type="text" placeholder="Search User by Name" />
              <MagnifyingGlassIcon />
            </div>
          </div>
          <div className="all-user-box">
            {userData.map((user, i) => (
              <div key={user._id}>
                <div
                  className="user-ad-box"
                  onClick={() => {
                    toggle(i);
                  }}
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
                    alt="#"
                  />
                  <p>{user.name}</p>
                  <p>{user.username}</p>
                  <p>{user.email}</p>
                  <p>
                    <Moment date={user.createdAt} format="dddd MM YYYY" />
                  </p>
                </div>
                <div className={`user-extra ${openExtra === i && "active"}`}>
                  <div>
                    <p>
                      admin: <span>{user.isAdmin ? "yes" : "No"}</span>{" "}
                    </p>
                    <p>
                      account type: <span>{user.accountType}</span>{" "}
                    </p>
                    <p>
                      trade platform: <span>{user.tradeplatform}</span>{" "}
                    </p>
                    <p>
                      deposit: <span>$ {user?.deposit}</span>{" "}
                    </p>
                    <p>
                      profit: <span>$ {user?.profit}</span>{" "}
                    </p>
                  </div>
                  <div>
                    <p>
                      phone number: <span>{user.phone}</span>{" "}
                    </p>
                    <p>
                      country: <span>{user.country}</span>{" "}
                    </p>
                    <p>
                      ref code: <span>{user.refcode}</span>{" "}
                    </p>
                    <p>
                      withdrawals: <span>$ {user?.widthdrawals}</span>{" "}
                    </p>
                    <p>
                      bonus: <span>$ {user?.bonus}</span>{" "}
                    </p>
                  </div>
                  {!user.isAdmin && (
                    <div className="users-actions">
                      <button
                        className="credit"
                        onClick={() => {
                          setDebitPop(false);
                          setCreditPop(true);
                          setBonusPop(false);
                          setProfitPop(false);
                          setRemoveBonus(false);
                          setRemoveProfit(false);
                        }}
                      >
                        credit
                      </button>
                      <button
                        className="debit"
                        onClick={() => {
                          setDebitPop(true);
                          setCreditPop(false);
                          setBonusPop(false);
                          setProfitPop(false);
                          setRemoveBonus(false);
                          setRemoveProfit(false);
                        }}
                      >
                        debit
                      </button>
                      <button
                        className="bonus"
                        onClick={() => {
                          setDebitPop(false);
                          setCreditPop(false);
                          setBonusPop(true);
                          setProfitPop(false);
                          setRemoveBonus(false);
                          setRemoveProfit(false);
                        }}
                      >
                        bonus
                      </button>
                      <button
                        className="bonus p"
                        onClick={() => {
                          setDebitPop(false);
                          setCreditPop(false);
                          setBonusPop(false);
                          setProfitPop(true);
                          setRemoveBonus(false);
                          setRemoveProfit(false);
                        }}
                      >
                        profit
                      </button>
                    </div>
                  )}
                  {!user.isAdmin && (
                    <div className="popup">
                      {creditPop && (
                        <div className="credit-box">
                          <input
                            type="number"
                            placeholder="Enter Amount to Credit User"
                            onChange={(e) => setCreditValue(e.target.value)}
                          />
                          <button
                            className="credit"
                            onClick={() =>
                              updateUserDeposit(user._id, user.deposit)
                            }
                          >
                            credit
                          </button>
                        </div>
                      )}
                      {debitPop && (
                        <div className="credit-box">
                          <input
                            type="number"
                            placeholder="Enter Amount to Debit User"
                            onChange={(e) => setDebitValue(e.target.value)}
                          />
                          <button
                            className="debit"
                            onClick={() =>
                              updateDebit(user._id, user.widthdrawals)
                            }
                          >
                            debit
                          </button>
                        </div>
                      )}
                      {bonusPop && (
                        <div className="credit-box">
                          <input
                            type="number"
                            placeholder="Enter Amount to Bonus User"
                            onChange={(e) => setBonusValue(e.target.value)}
                          />
                          <button
                            className="bonus"
                            onClick={() =>
                              userBonusUpdate(user._id, user.bonus)
                            }
                          >
                            bonus
                          </button>
                        </div>
                      )}
                      {profitPop && (
                        <div className="credit-box">
                          <input
                            type="number"
                            placeholder="Enter Amount to Profit User"
                            onChange={(e) => setProfitValue(e.target.value)}
                          />
                          <button
                            className="bonus p"
                            onClick={() =>
                              userProfitUpdate(user._id, user.profit)
                            }
                          >
                            profit
                          </button>
                        </div>
                      )}
                      {removeBonus && (
                        <div className="credit-box">
                          <input
                            type="number"
                            placeholder="Enter Amount to Remove From User Bonus"
                            onChange={(e) => setRbvalue(e.target.value)}
                          />
                          <button
                            className="debit"
                            onClick={() =>
                              Deduct(user._id, user.bonus, "bonus")
                            }
                          >
                            Remove Bonus
                          </button>
                        </div>
                      )}
                      {removeProfit && (
                        <div className="credit-box">
                          <input
                            type="number"
                            placeholder="Enter Amount to Remove From User Profit"
                            onChange={(e) => setRpvalue(e.target.value)}
                          />
                          <button
                            className="debit"
                            onClick={() =>
                              Deduct(user._id, user.profit, "profit")
                            }
                          >
                            remove profit
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {!user.isAdmin && (
                    <div className="users-actions">
                      <button
                        className="bonus"
                        onClick={() => {
                          setDebitPop(false);
                          setCreditPop(false);
                          setBonusPop(false);
                          setProfitPop(false);
                          setRemoveBonus(true);
                          setRemoveProfit(false);
                        }}
                      >
                        deduct bonus
                      </button>
                      <button
                        className="bonus p"
                        onClick={() => {
                          setDebitPop(false);
                          setCreditPop(false);
                          setBonusPop(false);
                          setProfitPop(false);
                          setRemoveBonus(false);
                          setRemoveProfit(true);
                        }}
                      >
                        deduct profit
                      </button>
                    </div>
                  )}
                  {user.isAdmin && <div></div>}
                  <div className="deletediv">
                    {" "}
                    <button
                      className="debit ap"
                      onClick={() => {
                        deleteUser(user._id);
                      }}
                    >
                      delete user
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
