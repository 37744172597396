import React, { useState } from "react";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearPlan } from "../redux/investRedux";
import { publicRequest } from "../requestMethod";
import Loader from "../components/Loader";

const Package = () => {
  const dispatch = useDispatch();
  const { selectedPlan } = useSelector((state) => state.invest);
  const { currentUser } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  console.log(currentUser, selectedPlan);
  const BTCaddress = "bc1qz4jtwk9mdzdn6hpc6y243xjqafmf2638kcexen";
  const USDTaddress = "TGjqBFD9pjyKD7FdPWbn9JSE7yrV3oHMa6";
  const navigate = useNavigate();

  const createOrder = async () => {
    setLoading(true);
    try {
      const data = await publicRequest.post("/order", {
        name: currentUser.name,
        amount: selectedPlan.mainPrice,
      });
      toast.info(data.data.message);
      setLoading(false);
      navigate("/");
      dispatch(clearPlan());
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="paxk">
      {loading && <Loader load={loading} />}
      <div className="container">
        <div className="packbtn">
          <Link to={"/"} className="packing-top">
            <ArrowUturnLeftIcon />
          </Link>
          <button
            className="pct-btn"
            onClick={() => {
              createOrder();
            }}
          >
            done
          </button>
        </div>
        <div className="packing-body">
          {selectedPlan.currency === "BTC" && (
            <p>
              you are about to buy <span>{selectedPlan.value} BTC </span> for{" "}
              <span>$ {selectedPlan.mainPrice}</span>
            </p>
          )}
          {selectedPlan.currency === "usd" && (
            <p>
              you are about to sell <span>{selectedPlan.value} USD </span> for{" "}
              <span>{selectedPlan.mainPrice} BTC</span>
            </p>
          )}
          <div className="packing-options">
            <div className="packing-boc">
              {selectedPlan.currency === "BTC" && (
                <span>
                  USDT WALLET TRC20{" "}
                  <img
                    src="https://cryptologos.cc/logos/tether-usdt-logo.png"
                    alt="#"
                    className="img-1"
                  />
                </span>
              )}
              {selectedPlan.currency === "usd" && (
                <span>
                  BTC WALLET{" "}
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/1200px-Bitcoin.svg.png"
                    alt="#"
                    className="img-1"
                  />
                </span>
              )}
              <div className="backing-box">
                <div className="backing-inner">
                  {selectedPlan.currency === "usd" && (
                    <img
                      src="/images/swiftbtc.png"
                      alt="#"
                      className="img-2"
                    />
                  )}
                  {selectedPlan.currency === "BTC" && (
                    <img
                      src="/images/swiftusdt.png"
                      alt="#"
                      className="img-2"
                    />
                  )}
                  {selectedPlan.currency === "usd" && (
                    <CopyToClipboard text={BTCaddress}>
                      <p
                        className="wall-add"
                        onClick={() => toast.info("Copied")}
                      >
                        {BTCaddress}
                      </p>
                    </CopyToClipboard>
                  )}
                  {selectedPlan.currency === "BTC" && (
                    <CopyToClipboard text={USDTaddress}>
                      <p
                        className="wall-add"
                        onClick={() => toast.info("Copied")}
                      >
                        {USDTaddress}
                      </p>
                    </CopyToClipboard>
                  )}
                  <span className="smalls">click to copy</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Package;
