import React, { useState } from "react";

const About = () => {
  const [tabs, setTabs] = useState(0);
  return (
    <div id="about">
      <div className="container">
        <h1 className="about-title">
          about <span>us</span>
        </h1>
        <div className="about-subtext">
          <p>
            A LICENSED COMMERCIAL WEBSITE THAT LISTS STOCKS, CFDS, EXCHANGES,
            COMMODITIES AND OTHER INVESTMENT RELATED INFO
          </p>
        </div>
        <div className="main-about">
          <div>
            <img src="/images/swift0.jpeg" alt="#" className="main-img"/>
          </div>
          <div>
            <h3 className="main-about-heading">WE ARE SWIFT GAINZ LIMITED ®</h3>

            <p>
              A place for everyone who wants to simply invest Bitcoins.
              Deposit funds using your Crypto Wallet. Instant
              trading of Bitcoins at fair price is guaranteed. Nothing extra.
              Join over 700,000 users from all over the world satisfied with our
              services.
            </p>
            <div>
              <div className="about-tab">
                <h3
                  className={`${tabs === 0 ? "active" : ""}`}
                  onClick={() => setTabs(0)}
                >
                  our mission
                </h3>
                <h3
                  className={`${tabs === 1 ? "active" : ""}`}
                  onClick={() => setTabs(1)}
                >
                  our advantages
                </h3>
                <h3
                  className={`${tabs === 2 ? "active" : ""}`}
                  onClick={() => setTabs(2)}
                >
                  our guarantees
                </h3>
              </div>
              {tabs === 0 ? (
                <p className="tab-txt">
                  Bitcoin is based on a protocol known as the blockchain, which
                  allows to create, transfer and verify ultra-secure financial
                  data without interference of third parties.
                </p>
              ) : null}
              {tabs === 1 ? (
                <p className="tab-txt">
                  Our mission as an official partner of Bitcoin Foundation is to help you enter and better understand the world of #1 cryptocurrency and avoid any issues you may encounter
                </p>
              ) : null}
              {tabs === 2 ? (
                <p className="tab-txt">
                 We are here because we are passionate about open, transparent markets and aim to be a major driving force in widespread adoption, we are the first and the best in cryptocurrency.
                </p>
              ) : null}
            </div>
            <button className="btn-abt">read more</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
