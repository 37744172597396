import React from "react";
import { Link } from "react-router-dom";

const Forgot = () => {
  return (
    <div className="login-main">
      <div className="login-box">
        <h2>password reset</h2>
        <form>
          <div className="user-box">
            <input type="email" name="" required />
            <label for="">Email</label>
          </div>
          <a href="/" className="spinLink google">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            SEND EMAIL NOW
          </a>
          <div className="dont">
            <p>repeat login?</p>
            <Link to={"/login"}>login</Link>
          </div>
          <p className="copy">
            © Copyright 2023 Swift Gainz Limited All Rights Reserved.
          </p>
        </form>
      </div>
    </div>
  );
};

export default Forgot;
