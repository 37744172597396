import React from "react";

const Minor = ({ image, title, subtext }) => {
  return (
    <div className="minor">
      <div>
        <img src={image} alt="#" />
      </div>
      <div>
        <h3 className="minor-text">{title}</h3>
        <p className="minor-sub">{subtext}</p>
      </div>
    </div>
  );
};

export default Minor;
