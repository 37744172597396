import React from "react";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <div className="banner">
      <div className="container">
        <div className="banner-inner">
          <h2>get started today with bitcoin</h2>
          <p>open accont for free and start trading bitcoins</p>
          <Link to={'/signup'}>register now</Link>
        </div>
      </div>
    </div>
  );
};

export default Banner;
