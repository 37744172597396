import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updatePlan } from "../redux/investRedux";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

const Pack = ({ value, mainPrice, currency, packPlan }) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  return (
    <div className="pack">
      {/* <h5>
        get {value} {currency}
      </h5>
      <p>for</p> */}
      <h1>
        {currency !== "usd" && "$"}
        {mainPrice}+ {currency === "usd" && "BTC"}
      </h1>
      <div>
        {packPlan.map((plan) => (
          <div id={plan.id} className="new-plan">
            <CheckCircleIcon />
            <p>{plan.text}</p>
          </div>
        ))}
      </div>
      {!currentUser && (
        <Link
          to={"/signup"}
          onClick={() => {
            dispatch(updatePlan({ value, currency, mainPrice }));
          }}
        >
          invest now
        </Link>
      )}
      {currentUser && (
        <Link
          to={currentUser?.isAdmin ? "/admin" : "/signup"}
          onClick={() => {
            dispatch(updatePlan({ value, currency, mainPrice }));
          }}
        >
          invest now
        </Link>
      )}
    </div>
  );
};

export default Pack;
