import React, { useState } from "react";
import { coins } from "../data";

const Calculator = () => {
  const [value, setValue] = useState(1);
  const [newValue, setNewValue] = useState(value * coins[0].value);
  return (
    <div id="calculator" className="calculator">
      <div className="container">
        <div className="calculator-body">
          <h1 className="about-title">
            <span>bitcoin</span> calculator
          </h1>
          <p>
            FIND OUT THE CURRENT BITCOIN VALUE WITH OUR EASY-TO-USE CONVERTER
          </p>
          <div className="cal-body">
            <div className="btc">
              <input
                type="number"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                
                }}
              />
              <span className="btc-logo">&#x20bf;</span>
            </div>
            <span className="equal">=</span>
            <div className="btc">
              <input type="number" value={value *newValue} />
              <span>
                <select
                  onChange={(e) => {
                    setNewValue(e.target.value);
                  }}
                >
                  {coins.map((coin) => (
                    <option value={coin.value} key={coin.name}>
                      {coin.name}
                    </option>
                  ))}
                </select>
              </span>
            </div>
          </div>
          <p className="btc-sub">*data updated every 15 minutes</p>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
