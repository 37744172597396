import React from "react";

const LiveReadings = () => {
  return (
    <div className="live">
      <div className="container">
        <div className="readings">
          <div className="live-box">
            <p>9,450 USD</p>
            <span>last trade price</span>
          </div>
          <div className="live-box">
            <p>+ 5.26%</p>
            <span>24 hour price</span>
          </div>
          <div className="live-box">
            <p>12.820 BTC</p>
            <span>24 hour volume</span>
          </div>
          <div className="live-box">
            <p>2,231,775</p>
            <span>active traders</span>
          </div>
          <div className="live-box">
            <p>$60730.30</p>
            <span>live asset price</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveReadings;
