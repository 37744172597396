import React from "react";
import { Link } from "react-router-dom";
import About from "../components/About";
import Banner from "../components/Banner";
import Calculator from "../components/Calculator";
import Ceo from "../components/Ceo";
import CurrencySlider from "../components/CurrencySlider";
import Experts from "../components/Experts";
import Footer from "../components/Footer";
import Intro from "../components/Intro";
import LatestTransaction from "../components/LatestTransaction";
import LiveReadings from "../components/LiveReadings";
import Navbar from "../components/Navbar";
import Packages from "../components/Packages";
import Showcase from "../components/Showcase";
import Steps from "../components/Steps";
import { UserIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";

const Home = () => {
  const { currentUser } = useSelector((state) => state.user);
  return (
    <div>
      <Navbar />
      {!currentUser && (
        <div className="home-login-sign">
          <Link to="/login" className="btn-login">
            <UserIcon />
            login
          </Link>
          <Link to="/signup" className="btn-signup">
            <UserPlusIcon />
            register
          </Link>
        </div>
      )}
      {currentUser && currentUser.isAdmin && (
        <div className="home-login-sign">
          <Link to="/admin" className="btn-login">
            admin
          </Link>
        </div>
      )}
      {currentUser && !currentUser.isAdmin && (
        <p className="welcome">{`You are Welcome ${currentUser.name}`}</p>
      )}
      <LiveReadings />
      <CurrencySlider />
      <Showcase />
      <Steps />
      <About />
      <Intro />
      <Packages />
      <Calculator />
      <Experts />
      <LatestTransaction />
      <Ceo />
      <Banner />
      <Footer />
    </div>
  );
};

export default Home;
