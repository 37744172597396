import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const Loader = ({load}) => {
  return (
    <div className="loader">
      <ClipLoader
        color='#4EA5D9'
        loading={load}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loader;
