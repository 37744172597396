import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedPlan: null,
};

const investSlice = createSlice({
  name: "invest",
  initialState,
  reducers: {
    updatePlan: (state, action) => {
      state.selectedPlan = action.payload;
    },
    clearPlan: (state) => {
      state.selectedPlan = null;
    },
  },
});

export const { updatePlan, clearPlan } = investSlice.actions;
export default investSlice.reducer;
