import React from "react";
import { motion } from "framer-motion";

const LatestTransaction = () => {
  return (
    <div className="transaction">
      <div className="container">
        <div className="transaction-inner">
          <h1 className="about-title">
            latest <span>withdrawals</span>
          </h1>

          <div className="table-container">
            <table className="transact-table">
              <thead>
                <tr>
                  <th>status</th>
                  <th>amount</th>
                  <th>transaction iD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className="table-complete pending">pending</span>
                  </td>
                  <td>
                    <span>$ 15,000</span>
                  </td>
                  <td>
                    <span>2537149091048458</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="table-complete complete">completed</span>
                  </td>
                  <td>
                    <span>$ 5,000</span>
                  </td>
                  <td>
                    <span>847104750285025729</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="table-complete pending">pending</span>
                  </td>
                  <td>
                    <span>$ 7,500</span>
                  </td>
                  <td>
                    <span>580347589138547673</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="table-complete complete">completed</span>
                  </td>
                  <td>
                    <span>$ 31,000</span>
                  </td>
                  <td>
                    <span>19563792089573858</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="table-complete pending">pending</span>
                  </td>
                  <td>
                    <span>$ 15,000</span>
                  </td>
                  <td>
                    <span>589471380387582095</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="table-complete complete">completed</span>
                  </td>
                  <td>
                    <span>$ 21,000</span>
                  </td>
                  <td>
                    <span>462895625449275927</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="table-complete complete">completed</span>
                  </td>
                  <td>
                    <span>$ 45,000</span>
                  </td>
                  <td>
                    <span>2544710475562675937</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="table-complete complete">completed</span>
                  </td>
                  <td>
                    <span>$ 55,500</span>
                  </td>
                  <td>
                    <span>3719462439254016</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="table-complete complete">completed</span>
                  </td>
                  <td>
                    <span>$ 12,000</span>
                  </td>
                  <td>
                    <span>3465720472434940</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            exit={{ y: 100, opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="pop-up-recieve"
          >
            <p>
              someone from <span className="locate">UAE</span> just recieved{" "}
              <span className="locate-recieve">$150,000</span>
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default LatestTransaction;
