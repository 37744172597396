import React from "react";
import { Link } from "react-router-dom";

const ExpertCard = ({ image, facebook, twitter, google, name, position }) => {
  return (
    <div className="team-member">
      <img src={image} alt="#" />
      <div className="team-info">
        <h4>{name}</h4>
        <p>{position}</p>
      </div>
      <div className="team-socials">
        <Link to={facebook} className="social-inner">
          <i className="fa-brands fa-facebook-f"></i>
        </Link>
        <Link to={twitter} className="social-inner">
          <i className="fa-brands fa-twitter"></i>
        </Link>
        <Link to={google} className="social-inner">
          <i className="fa-brands fa-google-plus-g"></i>
        </Link>
      </div>
    </div>
  );
};

export default ExpertCard;
