export const coins = [
  {
    name: "usd",
    value: 60730.30,
  },
];

export const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic of the",
  "Congo, Republic of the",
  "Costa Rica",
  "Cote d'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste (East Timor)",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City (Holy See)",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const USDpacks = [
  {
    id: 1,
    value: 0.017,
    currency: "BTC",
    mainPrice: 500,
    plan: "regular",
    planPack: [
      {
        id: 1,
        text: "5% deposit bonus",
      },
      {
        id: 2,
        text: "no risk free trade",
      },
      {
        id: 3,
        text: "no training",
      },
      {
        id: 4,
        text: "no free signals",
      },
      {
        id: 5,
        text: "junior broker",
      },
      {
        id: 6,
        text: "24/7 support",
      },
    ],
  },
  {
    id: 2,
    value: 0.035,
    currency: "BTC",
    mainPrice: 1500,
    plan: "silver",
    planPack: [
      {
        id: 1,
        text: "10% deposit bonus",
      },
      {
        id: 2,
        text: "5x risk free trade",
      },
      {
        id: 3,
        text: "basic training",
      },
      {
        id: 4,
        text: "expert signals",
      },
      {
        id: 5,
        text: "professional broker",
      },
      {
        id: 6,
        text: "24/7 support",
      },
    ],
  },
  {
    id: 3,
    value: 0.052,
    currency: "BTC",
    mainPrice: 5000,
    plan: "gold",
    planPack: [
      {
        id: 1,
        text: "15% deposit bonus",
      },
      {
        id: 2,
        text: "10x risk free trade",
      },
      {
        id: 3,
        text: "full training",
      },
      {
        id: 4,
        text: "premium signals",
      },
      {
        id: 5,
        text: "executive broker",
      },
      {
        id: 6,
        text: "24/7 support",
      },
    ],
  },
  {
    id: 4,
    value: 0.07,
    currency: "BTC",
    mainPrice: 9000,
    plan: "platinum",
    planPack: [
      {
        id: 1,
        text: "20% deposit bonus",
      },
      {
        id: 2,
        text: "15x risk free trade",
      },
      {
        id: 3,
        text: "advanced full training",
      },
      {
        id: 4,
        text: "platinum signals",
      },
      {
        id: 5,
        text: "executive broker + analyst",
      },
      {
        id: 6,
        text: "24/7 support",
      },
    ],
  },
];
export const BTCpacks = [
  {
    id: 1,
    value: 6000,
    currency: "usd",
    mainPrice: " 0.2",
  },
  {
    id: 2,
    value: 14500,
    currency: "usd",
    mainPrice: " 0.5",
  },
  {
    id: 3,
    value: 29000,
    currency: "usd",
    mainPrice: "1",
  },
  {
    id: 4,
    value: 58000,
    currency: "usd",
    mainPrice: "2",
  },
];
