import React, { useEffect, useState } from "react";
import { ArrowUturnLeftIcon, WifiIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../redux/userRedux";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { publicRequest } from "../requestMethod";

const Account = () => {
  const dispatch = useDispatch();
  const [action, setAction] = useState("deposit");
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [writtenAmount, setWrittenAmount] = useState(0);
  const [showWallet, setShowWallet] = useState(false);
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const BTCaddress = "bc1qz4jtwk9mdzdn6hpc6y243xjqafmf2638kcexen";

  useEffect(() => {
    if (currentUser === null) {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const depositBtn = () => {
    if (selectedAmount !== 0) {
      setShowWallet(true);
    } else if (writtenAmount < 500) {
      toast.info("Please enter amount above $500");
    } else if (writtenAmount >= 500) {
      setShowWallet(true);
    } else {
      console.log("make a choice to continue");
    }
  };

  const createOrder = async () => {
    try {
      const data = await publicRequest.post("/order", {
        name: currentUser.name,
        amount: selectedAmount || writtenAmount,
      });
      console.log(data);
      toast.info(data.data.message);
      setSelectedAmount(0);
      setWrittenAmount(0);
      setShowWallet(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="container">
        <div className="adt-tp">
          <div className="packbtn">
            <Link to={"/"} className="packing-top">
              <ArrowUturnLeftIcon />
            </Link>
            <div>
              <Link to={`/dashboard/${currentUser._id}`} className="link-new">
                dashboard
              </Link>
            </div>
            <Link
              to={"/"}
              className="log-signup"
              onClick={() => {
                dispatch(logOut());
              }}
            >
              log out
            </Link>
          </div>
        </div>
        <div className="user-account-inner-top">
          <h4>deposit / Withdrawal</h4>
          <div className="signal-fee">
            <WifiIcon />
            <p>signal fees: $0.00</p>
          </div>
        </div>
        <div className="depo-acc">
          <div className="depo-acc-links">
            <p onClick={() => setAction("deposit")}>deposit</p>
            <p onClick={() => setAction("bitcoin")}>Withdraw (bitcoin)</p>
            <p onClick={() => setAction("bank")}>Withdraw (bank)</p>
          </div>
          {action === "deposit" && (
            <div className="wown">
              <div className="invest-opts">
                <div
                  className={`${selectedAmount === 30000 && "opts-select"}`}
                  onClick={() => {
                    setSelectedAmount(30000);
                    setWrittenAmount(0);
                  }}
                >
                  <p>$30,000</p>
                </div>
                <div
                  className={`${selectedAmount === 20000 && "opts-select"}`}
                  onClick={() => {
                    setSelectedAmount(20000);
                    setWrittenAmount(0);
                  }}
                >
                  <p>$20,000</p>
                </div>
                <div
                  className={`${selectedAmount === 10000 && "opts-select"}`}
                  onClick={() => {
                    setSelectedAmount(10000);
                    setWrittenAmount(0);
                  }}
                >
                  <p>$10,000</p>
                </div>
                <div
                  className={`${selectedAmount === 5000 && "opts-select"}`}
                  onClick={() => {
                    setSelectedAmount(5000);
                    setWrittenAmount(0);
                  }}
                >
                  <p>$5,000</p>
                </div>
                <div
                  className={`${selectedAmount === 1000 && "opts-select"}`}
                  onClick={() => {
                    setWrittenAmount(0);
                    setSelectedAmount(1000);
                  }}
                >
                  <p>$1,000</p>
                </div>
                <div
                  className={`${selectedAmount === 500 && "opts-select"}`}
                  onClick={() => {
                    setSelectedAmount(500);
                    setWrittenAmount(0);
                  }}
                >
                  <p>$500</p>
                </div>
              </div>
              <select>
                <option value="none">choose a payment option</option>
                <option value="bitcoin">bitcoin</option>
              </select>
              <div>
                <input
                  type="number"
                  placeholder="Amount"
                  onChange={(e) => {
                    setWrittenAmount(e.target.value);
                    setSelectedAmount(0);
                  }}
                  value={writtenAmount}
                />
              </div>
              {selectedAmount !== 0 && (
                <>
                  <p className="plain">
                    Amount + broker management charges is approximately
                  </p>{" "}
                  <p className="sub-total">
                    {selectedAmount + (selectedAmount * 5) / 100}
                  </p>
                </>
              )}
              {writtenAmount >= 500 && (
                <>
                  <p className="plain">
                    Amount + broker management charges is approximately
                  </p>{" "}
                  <p className="sub-total">
                    {Number(writtenAmount) + (writtenAmount * 5) / 100}
                  </p>
                </>
              )}
              <button onClick={() => depositBtn()}>proceed to payment</button>
            </div>
          )}
          {action === "bitcoin" && (
            <div className="wown">
              <h5>Withdraw (bitcoin)</h5>
              <div>
                <input type="number" placeholder="Amount (USD)" />
                <input type="text" placeholder="Bitcoin Wallet Address" />
              </div>
              <button>proceed to payment</button>
            </div>
          )}
          {action === "bank" && (
            <div className="wown">
              <h5>Withdraw (bank)</h5>
              <div>
                <input type="number" placeholder="Amount (USD)" />
                <input type="number" placeholder="Account Number" />
                <input type="text" placeholder="Account Name" />
                <input type="text" placeholder="Bank Name" />
              </div>
              <button>proceed to payment</button>
            </div>
          )}
        </div>
        {showWallet && (
          <div className="payment-div">
            <p>
              you are about to pay ${selectedAmount !== 0 && selectedAmount}
              {writtenAmount !== 0 && writtenAmount}
            </p>
            <p>please pay BTC equivalent directly into this wallet address</p>
            <p>once payment is made click on the button below</p>
            <img src="./images/swiftbtc.png" alt="#" />
            <CopyToClipboard text={BTCaddress}>
              <p className="wall-add" onClick={() => toast.info("Copied")}>
               {BTCaddress}
              </p>
            </CopyToClipboard>
            {/* <CopyToClipboard text={'3DhqfrJ3bJ9miWBqm7sz6kLN6cpTBMufsA'}>
              <p className="wall-add" onClick={() => toast.info("Copied")}>
                3DhqfrJ3bJ9miWBqm7sz6kLN6cpTBMufsA
              </p>
            </CopyToClipboard> */}
            <button onClick={() => createOrder()}>payment made</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Account;
