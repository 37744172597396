import React, { useState } from "react";
import Pack from "./Pack";
import { USDpacks, BTCpacks } from "../data";

const Packages = () => {
  // eslint-disable-next-line
  const [buy, setBuy] = useState(false);
  return (
    <div id="packages" className="packages">
      <div className="container">
        <h1 className="about-title">
          affordable <span>packages</span>
        </h1>
        {/* <div className="about-subtext">
          <p>
            PURCHASE BITCOIN USING A CREDIT CARD OR WITH YOUR LINKED BANK
            ACCOUNT
          </p>
        </div>
        <div className="buy-sell">
          <div className="buy-inner">
            <p onClick={() => setBuy(false)}>buy</p>
            <p onClick={() => setBuy(true)}>sell</p>
            <span className={`${buy ? "isAct" : "isActnt"}`}></span>
          </div>
        </div> */}
        {!buy ? (
          <div className="packs">
            {USDpacks.map((pack) => (
              <Pack
                value={pack.value}
                currency={pack.currency}
                mainPrice={pack.mainPrice}
                packPlan={pack.planPack}
                key={pack.id}
              />
            ))}
          </div>
        ) : (
          <div className="packs">
            {BTCpacks.map((pack) => (
              <Pack
                value={pack.value}
                currency={pack.currency}
                mainPrice={`${pack.mainPrice}`}
                key={pack.id}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Packages;
