import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loginFailure, loginStart, loginSuccess } from "../redux/userRedux";
import Loader from "../components/Loader";
import { publicRequest } from "../requestMethod";
import { toast } from "react-toastify";

const Login = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    dispatch(loginStart());
    setLoader(true);
    try {
      const data = await publicRequest.post("/user/login", {
        username,
        password,
      });
      toast.success(data.data.message);
      dispatch(loginSuccess(data.data));
    } catch (error) {
      toast.error(error.response.data.error);
      setLoader(false);
      console.log(error);
      dispatch(loginFailure());
    }
  };

  useEffect(() => {
    if (currentUser?.isAdmin) {
      navigate("/admin");
    } else if (currentUser === null) {
      navigate("/login");
    } else {
      navigate(`/dashboard/${currentUser?._id}`, { state: currentUser });
    }
    // eslint-disable-next-line
  }, [currentUser]);
  return (
    <div className="login-main">
      {loader && <Loader load={loader} />}
      <div className="login-box">
        <h2>login</h2>
        <form>
          <div className="user-box">
            <input
              type="text"
              required
              onChange={(e) => setUserName(e.target.value)}
            />
            <label>Username</label>
          </div>
          <div className="user-box">
            <input
              type="password"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            <label>Password</label>
          </div>
          <div className="remeber">
            <div>
              <input type="checkbox" name="" id="remember" />
              <label htmlFor="remember">remember me</label>
            </div>
            <Link to="/forget">forgot password?</Link>
          </div>
          <a href="/" className="spinLink" onClick={(e) => handleLogin(e)}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            sign in
          </a>
          {/* <p className="or">or login with </p> */}

          {/* <a href="/" className="spinLink google">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <img
              src="https://icones.pro/wp-content/uploads/2021/02/google-icone-symbole-png-logo-bleu.png"
              alt="#"
            />
            GOOGLE
          </a> */}
          <div className="dont">
            <p>don't have an account?</p>
            <Link to={"/signup"}>sign up</Link>
          </div>
          <p className="copy">
            © Copyright 2023 Swift Gainz Limited All Rights Reserved.
          </p>
        </form>
      </div>
    </div>
  );
};

export default Login;
