import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  ArrowUturnLeftIcon,
  BanknotesIcon,
  CurrencyDollarIcon,
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
} from "@heroicons/react/24/outline";
import { publicRequest } from "../requestMethod";
import Loader from "../components/Loader";
import { useDispatch } from "react-redux";
import { logOut } from "../redux/userRedux";
import TradingViewWiddget from "../components/TradingViewWidget";
import TradingSmall from "../components/TradingSmall";
import TradingViewHotlistsWidget from "../components/TradingViewHotlistsWidget";

const Dashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [userDetails, setUserDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const id = location.pathname.split("/")[2];
  const getUser = async () => {
    setLoading(true);
    try {
      const data = await publicRequest.get("/user/find/" + id);
      console.log(data);
      setUserDetails(data.data.others);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      {loading && <Loader />}
      <div className="container">
        <div className="adt-tp">
          <div className="packbtn">
            <Link to={"/"} className="packing-top">
              <ArrowUturnLeftIcon />
            </Link>
            <div>
              <Link to={"/account"} className="link-new">
                deposit/widthdraw
              </Link>
            </div>
            <Link
              to={"/"}
              className="log-signup"
              onClick={() => {
                dispatch(logOut());
              }}
            >
              log out
            </Link>
          </div>
        </div>
        <div className="dash-head">
          <h3 className="dash-heading">welcome, {userDetails?.name}</h3>
          {/* <p>account is <span>active</span></p> */}
        </div>
        {/* <div className="user-warn">
          <p>Due to the time it takes to run and manage your account, you must pay the $150 maintenance fee in order to successfully update your account and withdraw your money.</p>
        </div> */}
        <div className="dash-user-cont">
          <div className="dash-user-box">
            <div className="dash-user-text">
              <p>account balance</p>
              <h3>
                ${" "}
                {userDetails?.profit +
                  userDetails?.bonus +
                  userDetails?.deposit +
                  userDetails?.refferalBonus -
                  userDetails?.widthdrawals}
              </h3>
            </div>
            <div className="dash-icon">
              <CurrencyDollarIcon />
            </div>
          </div>
          <div className="dash-user-box">
            <div className="dash-user-text">
              <p>total profit</p>
              <h3>$ {userDetails?.profit}</h3>
            </div>
            <div className="dash-icon">
              <BanknotesIcon />
            </div>
          </div>
          <div className="dash-user-box">
            <div className="dash-user-text">
              <p>total deposits</p>
              <h3>$ {userDetails?.deposit}</h3>
            </div>
            <div className="dash-icon">
              <ArrowDownTrayIcon />
            </div>
          </div>
          <div className="dash-user-box">
            <div className="dash-user-text">
              <p>bonus</p>
              <h3>$ {userDetails?.bonus}</h3>
            </div>
            <div className="dash-icon">
              <ArrowUturnLeftIcon />
            </div>
          </div>
          {/* <div className="dash-user-box">
            <div className="dash-user-text">
              <p>refferal bonus</p>
              <h3>$ {userDetails?.refferalBonus}</h3>
            </div>
            <div className="dash-icon">
              <ArrowUturnLeftIcon />
            </div>
          </div> */}
          <div className="dash-user-box">
            <div className="dash-user-text">
              <p>total witdrawals</p>
              <h3>$ {userDetails?.widthdrawals}</h3>
            </div>
            <div className="dash-icon">
              <ArrowUpTrayIcon />
            </div>
          </div>
        </div>
        <TradingViewWiddget />
        <TradingSmall />
        <TradingViewHotlistsWidget />
      </div>
    </div>
  );
};

export default Dashboard;
