import React from "react";
import TradeViewChart from "react-crypto-chart";

const Ceo = () => {
  return (
    <div id="ceo" className="ceo">
      <div className="quote">
        <p className="quote-text">
          Bitcoin is one of the most important inventions in all of human
          history. For the first time ever, anyone can send or receive any
          amount of money with anyone else, anywhere on the planet, conveniently
          and without restriction. It’s the dawn of a better, more free world.
        </p>
        <div className="ceo-name">
          <img src="https://images.unsplash.com/photo-1624797432677-6f803a98acb3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YnVzaW5lc3MlMjBtYW58ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=600&q=60" alt="#" />
          <p>
            <span>jose smith</span> - ceo
          </p>
        </div>
      </div>

      <div className="container">
        <div className="chart">
          <TradeViewChart
            interval="5s"
            containerStyle={{
              height: "10vh",
              width: "10vw",
            }}
            chartLayout={{
              layout: {
                backgroundColor: "black",
                textColor: "white",
              },
              grid: {
                vertLines: {
                  color: "black",
                  // style: LineStyle.SparseDotted,
                },
                horzLines: {
                  color: "black",
                  // style: LineStyle.SparseDotted,
                },
              },
              priceScale: {
                borderColor: "#485c7b",
              },
              timeScale: {
                borderColor: "#485c7b",
                timeVisible: true,
                secondsVisible: false,
              },
            }}
            candleStickConfig={{
              upColor: "green",
              downColor: "red",
              borderDownColor: "transparent",
              borderUpColor: "transparent",
              wickDownColor: "gray",
              wickUpColor: "gray",
            }}
            pair="BTCUSDT"
          />
        </div>
      </div>
    </div>
  );
};

export default Ceo;
