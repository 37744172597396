import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { countries } from "../data";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  registerFailure,
  registerStart,
  registerSuccess,
} from "../redux/userRedux";
import { publicRequest } from "../requestMethod";
import { toast } from "react-toastify";

const Signup = () => {
  const [tradingPlatform, setTradingPlatform] = useState("mt5");
  const [accountType, setAccountType] = useState("personal live account");
  const [username, setUsername] = useState("");
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState(countries[0]);
  const [ref, setRef] = useState("");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentUser } = useSelector((state) => state.user);

  const handleClick = async (e) => {
    e.preventDefault();
    dispatch(registerStart());
    setLoader(true);
    try {
      const data = await publicRequest.post("/user/register", {
        name: fullname,
        username: username,
        email: email,
        phone: phone,
        tradeplatform: tradingPlatform,
        accountType: accountType,
        country: country,
        password: password,
        refcode: ref,
      });
      dispatch(registerSuccess(data.data.data));
      toast.success(data.message);
      setLoader(false);
      navigate("/");
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(registerFailure());
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentUser?.isAdmin) {
      navigate("/admin");
    } else if (currentUser === null) {
      navigate("/signup");
    } else {
      navigate(`/dashboard/${currentUser?._id}`, { state: currentUser });
    }
    // eslint-disable-next-line
  }, [currentUser]);

  return (
    <div className="reg-main">
      {loader && <Loader />}
      <div className="login-box">
        <h2>create an account</h2>
        <form>
          <div className="optn">
            <select onChange={(e) => setTradingPlatform(e.target.value)}>
              <option value="MT5+">MT5+</option>
              <option value="MT4+">MT4+</option>
              <option value="Guidants">Guidants</option>
            </select>
            <label>select trading platform</label>
          </div>
          <div className="optn">
            <select onChange={(e) => setAccountType(e.target.value)}>
              <option value="personal live account">
                personal live account
              </option>
              <option value="corporate account">corporate account</option>
              <option value="joint account">joint account</option>
            </select>
            <label>select account type</label>
          </div>
          <div className="user-box">
            <input
              type="text"
              required
              onChange={(e) => setUsername(e.target.value)}
            />
            <label>Username</label>
          </div>
          <div className="user-box">
            <input
              type="text"
              required
              onChange={(e) => setFullname(e.target.value)}
            />
            <label for="">FullName</label>
          </div>
          <div className="user-box">
            <input
              type="email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <label>Email</label>
          </div>
          <div className="user-box">
            <input
              type="number"
              required
              onChange={(e) => setPhone(e.target.value)}
            />
            <label>Phone Number</label>
          </div>
          <div className="user-box">
            <input
              type="password"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            <label>Password</label>
          </div>
          <div className="user-box">
            <input type="password" required />
            <label>Confirm Password</label>
          </div>
          <div className="optn">
            <select onChange={(e) => setCountry(e.target.value)}>
              {countries.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </select>
            <label>country</label>
          </div>
          <div className="user-box">
            <input
              type="text"
              required
              onChange={(e) => setRef(e.target.value)}
            />
            <label>Refferal ID</label>
          </div>
          <a href="/" className="spinLink" onClick={(e) => handleClick(e)}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            register
          </a>
          {/* <p className="or">or sign up with </p> */}

          {/* <a href="/" className="spinLink google">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <img
              src="https://icones.pro/wp-content/uploads/2021/02/google-icone-symbole-png-logo-bleu.png"
              alt="#"
            />
            GOOGLE
          </a> */}
          <div className="dont">
            <p>already have an account?</p>
            <Link to={"/login"}>login</Link>
          </div>
          <p className="copy">
            © Copyright 2023 Swift Gainz Limited All Rights Reserved.
          </p>
        </form>
      </div>
    </div>
  );
};

export default Signup;
