import React from "react";

const IntroMinor = ({ image, title, text }) => {
  return (
    <div className="intro-minor">
      <div>
        <img src={image} alt="#" />
      </div>
      <h5>{title}</h5>
      <p>{text}</p>
    </div>
  );
};

export default IntroMinor;
