import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="foot">
      <div className="container">
        <div className="foot-inner">
          <div className="up">
            <div className="left">
              <div className="foot-box">
                <h5>our company</h5>
                <Link to={"#"}>home</Link>
                <Link to={"#"}>about</Link>
                <Link to={"#"}>services</Link>
                <Link to={"#"}>investment</Link>
                <Link to={"#"}>blog</Link>
                <Link to={"#"}>contact</Link>
              </div>
              <div className="foot-box">
                <h5>help & support</h5>
                <Link to={"#"}>faq</Link>
                <Link to={"#"}>terms of services</Link>
                <Link to={"#"}>license</Link>
                <Link to={"#"}>register</Link>
                <Link to={"#"}>login</Link>
                <Link to={"#"}>management</Link>
              </div>
              <div className="foot-box">
                <h5>Contact us</h5>
                <p>SWIFTGAINZ@AOL.COM</p>
                <p>+44 7852 775993</p>
                <p>4 fitzjames avenue, london</p>
                <p>united kingdom, W140RP</p>
                <p>MON-SAT 08AM ⇾ 05PM</p>
                <p className="foot-social">
                  <div className="foot-social-cont">
                    <i className="fa-brands fa-facebook-f"></i>
                  </div>
                  <div className="foot-social-cont">
                    <i className="fa-brands fa-twitter"></i>
                  </div>
                  <div className="foot-social-cont">
                    <i className="fa-brands fa-google-plus-g"></i>
                  </div>
                  <div className="foot-social-cont">
                    <i className="fa-brands fa-linkedin-in"></i>
                  </div>
                </p>
              </div>
            </div>
            <div className="right">
              <div className="tp">
                <div className="foot-box">
                  <h5>$298.76B</h5>
                  <p>market cap</p>
                </div>
                <div className="foot-box">
                  <h5>243k</h5>
                  <p>daily transactions</p>
                </div>
                <div className="foot-box">
                  <h5>319k</h5>
                  <p>active accounts</p>
                </div>
                <div className="foot-box">
                  <h5>217</h5>
                  <p>supported countries</p>
                </div>
              </div>
              {/* <div className="bt">
                <div className="foot-box">
                  <p>supported payment methods</p>
                </div>
                <div>
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/349/349228.png"
                    alt="#"
                  />
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/MasterCard_Logo.svg/1280px-MasterCard_Logo.svg.png"
                    alt="#"
                  />
                  <img
                    src="https://static.vecteezy.com/system/resources/previews/022/100/873/original/visa-logo-transparent-free-png.png"
                    alt="#"
                  />
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/PayPal_logo.svg/2560px-PayPal_logo.svg.png"
                    alt="#"
                  />
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/Maestro_logo.png/640px-Maestro_logo.png"
                    alt="#"
                  />
                </div>
              </div> */}
            </div>
          </div>
          <div className="down">
            <p>Copyright © 2013 - 2021 Swift Gainz LLC</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
