import React from "react";
import { Link } from "react-router-dom";

const CurrencySlider = () => {
  return (
    <div className="main-slide">
      <div className="curslider">
        <div className="curslider-track">
          <Link to={"/"} className="curslide">
            <div className="slide-name">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/1200px-Bitcoin.svg.png"
                alt="#"
              />
              <p>bitcoin</p>
            </div>
            <div className="slide-text">
              <span>60730.30</span>
              <span className="red">-16.00 (-0.33%)</span>
            </div>
          </Link>
          <Link to={"/"} className="curslide">
            <div className="slide-name">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Ethereum_logo_2014.svg/1257px-Ethereum_logo_2014.svg.png"
                alt="#"
              />
              <p>gold</p>
            </div>
            <div className="slide-text">
              <span>1644.90</span>
              <span className="green">+2.73 (+1.44%)</span>
            </div>
          </Link>
          <Link to="/" className="curslide">
            <div className="slide-name">
              <img
                src="https://s3-symbol-logo.tradingview.com/indices/nasdaq-100.svg"
                alt="#"
              />
              <p>nasdaq</p>
            </div>
            <div className="slide-text">
              <span>12079.00</span>
              <span className="green">+102.8 (+1.44%)</span>
            </div>
          </Link>
          <Link to={"/"} className="curslide">
            <div className="slide-name">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/1200px-Bitcoin.svg.png"
                alt="#"
              />
              <p>bitcoin</p>
            </div>
            <div className="slide-text">
              <span>23564.00</span>
              <span className="red">-16.00 (-0.33%)</span>
            </div>
          </Link>
          <Link to={"/"} className="curslide">
            <div className="slide-name">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Ethereum_logo_2014.svg/1257px-Ethereum_logo_2014.svg.png"
                alt="#"
              />
              <p>gold</p>
            </div>
            <div className="slide-text">
              <span>1644.90</span>
              <span className="green">+2.73 (+1.44%)</span>
            </div>
          </Link>
          <Link to="/" className="curslide">
            <div className="slide-name">
              <img
                src="https://s3-symbol-logo.tradingview.com/indices/nasdaq-100.svg"
                alt="#"
              />
              <p>nasdaq</p>
            </div>
            <div className="slide-text">
              <span>12079.00</span>
              <span className="green">+102.8 (+1.44%)</span>
            </div>
          </Link>
          <Link to={"/"} className="curslide">
            <div className="slide-name">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/1200px-Bitcoin.svg.png"
                alt="#"
              />
              <p>bitcoin</p>
            </div>
            <div className="slide-text">
              <span>23564.00</span>
              <span className="red">-16.00 (-0.33%)</span>
            </div>
          </Link>
          <Link to={"/"} className="curslide">
            <div className="slide-name">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Ethereum_logo_2014.svg/1257px-Ethereum_logo_2014.svg.png"
                alt="#"
              />
              <p>gold</p>
            </div>
            <div className="slide-text">
              <span>1644.90</span>
              <span className="green">+2.73 (+1.44%)</span>
            </div>
          </Link>
          <Link to="/" className="curslide">
            <div className="slide-name">
              <img
                src="https://s3-symbol-logo.tradingview.com/indices/nasdaq-100.svg"
                alt="#"
              />
              <p>nasdaq</p>
            </div>
            <div className="slide-text">
              <span>12079.00</span>
              <span className="green">+102.8 (+1.44%)</span>
            </div>
          </Link>
          <Link to={"/"} className="curslide">
            <div className="slide-name">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/1200px-Bitcoin.svg.png"
                alt="#"
              />
              <p>bitcoin</p>
            </div>
            <div className="slide-text">
              <span>23564.00</span>
              <span className="red">-16.00 (-0.33%)</span>
            </div>
          </Link>
          <Link to={"/"} className="curslide">
            <div className="slide-name">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Ethereum_logo_2014.svg/1257px-Ethereum_logo_2014.svg.png"
                alt="#"
              />
              <p>gold</p>
            </div>
            <div className="slide-text">
              <span>1644.90</span>
              <span className="green">+2.73 (+1.44%)</span>
            </div>
          </Link>
          <Link to="/" className="curslide">
            <div className="slide-name">
              <img
                src="https://s3-symbol-logo.tradingview.com/indices/nasdaq-100.svg"
                alt="#"
              />
              <p>nasdaq</p>
            </div>
            <div className="slide-text">
              <span>12079.00</span>
              <span className="green">+102.8 (+1.44%)</span>
            </div>
          </Link>
        </div>
      </div>
      <span className="slap-txt">Swift Gainz current open markets</span>
    </div>
  );
};

export default CurrencySlider;
